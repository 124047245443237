import React from 'react';
import {Outlet} from "react-router-dom";
import {Container, Nav, Navbar} from "react-bootstrap";

export default function Layout(props: {name: string}) {

    return (
        <div>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand>DEMO</Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {
                                props.name === "Admin" ?
                                    <>
                                        <Nav.Link href="/view/uebersicht">Übersicht</Nav.Link>
                                        <Nav.Link href="/view/user">Info</Nav.Link>
                                    </>
                                :
                                    <>
                                        <Nav.Link href="/view/user/erfassen">Erfassen</Nav.Link>
                                        <Nav.Link href="/view/user/settings">Settings</Nav.Link>
                                    </>
                            }
                            <Nav.Link href="/">Logout</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            Eingeloggt als: {props.name}
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Outlet />
        </div>
    );
}