import {TimeData, TimeEntry} from "../react-app-env";
import moment from "moment/moment";

export default class DataService {

    private static INSTANCE: DataService | null = null;
    private readonly URL: string = "";

    public static getInstance(): DataService {
        if(DataService.INSTANCE == null) {
            DataService.INSTANCE = new DataService();
        }
        return DataService.INSTANCE;
    }

    public getUserWorkTimes(setData: (p: TimeData) => void, setLoading: (p:boolean) => void): void {
        const items: TimeEntry[] = [];
        this.addEntry(items, 1);
        this.addEntry(items, 2);
        this.addEntry(items, 4);
        this.addEntry(items, 5);
        this.addEntry(items, 6);
        this.addEntry(items, 7);
        this.addEntry(items, 8);
        this.addEntry(items, 9);
        this.addEntry(items, 10);
        console.log(items);
        setData({
            items:items,
            groups:[
                { id: 1, title: 'Emmaline Cruise', rightTitle: 'Groupe 1' },
                { id: 2, title: 'Michaella De Cruce', rightTitle: 'Groupe 1' },
                { id: 3, title: 'Aurelea Nell', rightTitle: 'Groupe 1' },
                { id: 4, title: 'Timoteo Hexam', rightTitle: 'Groupe 1' },
                { id: 5, title: 'Denys Shackleford', rightTitle: 'Groupe 2' },
                { id: 6, title: 'Rolland Esposito', rightTitle: 'Groupe 2' },
                { id: 7, title: 'Jobye Bratt', rightTitle: 'Groupe 3' },
                { id: 8, title: 'Normand Alloway', rightTitle: 'Groupe 3' },
                { id: 9, title: 'Gayleen Llorente', rightTitle: 'Groupe 3' },
                { id: 10, title: 'Briana Barukh', rightTitle: 'Groupe 4' }
            ]
        });
        setLoading(false);
    }

    private addEntry(items: TimeEntry[], group: number): void {
        items.push(this.createTimeEntry(items.length+1, group, -2, this.randomInt(6, 10)));
        items.push(this.createTimeEntry(items.length+1, group, -1, this.randomInt(6, 10)));
        items.push(this.createTimeEntry(items.length+1, group, 0, this.randomInt(6, 10)));
    }

    private createTimeEntry(id: number, group: number, dayOf: number, std: number): TimeEntry {
        const start = moment().add(dayOf, 'day').startOf('day').add(8, 'hour').valueOf();
        const end = Math.trunc(start + (std * 3600000));

        return {
            id: id,
            group: group,
            start_time: start,
            end_time: end,
            canMove: false,
            canResize: false,
            canChangeGroup: false
        };
    }

    private randomInt(min: number, max: number): number {
        return Math.random() * (max - min) + min;
    }
}