import React, {useEffect, useMemo, useState} from 'react';
import Timeline from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import {Container} from "react-bootstrap";
import moment from 'moment'
import DataService from "../../service/DataService";
import {TimeData} from "../../react-app-env";

export default function Uebersicht() {
    const service = useMemo(() => DataService.getInstance(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TimeData>({groups:[], items:[]});

    useEffect(() => {
        setLoading(true);
        service.getUserWorkTimes(setData, setLoading);
    }, [service]);

    return (
        <Container>
            {
                loading ?
                    <div className="spinner-grow" role="status" />
                :
                    <Timeline
                        groups={data.groups}
                        items={data.items}
                        rightSidebarWidth={200}
                        defaultTimeStart={moment().add(-12, 'hour').startOf('day')}
                        defaultTimeEnd={moment().add(12, 'hour').startOf('day')}
                    />
            }
        </Container>
    );
}