import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
      <React.StrictMode>
          <BrowserRouter>
              <RecoilRoot>
              <App />
              </RecoilRoot>
          </BrowserRouter>
      </React.StrictMode>
);
