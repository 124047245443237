import React, {useState} from 'react';
import {Button, Card, Col, FloatingLabel, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {user as userAtom} from "../atoms/user";

export default function Login() {
    const [user, setUser] = useState<string>('');
    const setUsername = useSetRecoilState(userAtom);
    const [password, setPassword] = useState<string>('');

    const navigate = useNavigate();

    return (
        <div className="container-fluid h-100">
            <div className="row justify-content-center align-items-center h-100">
                <div className="col col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <Card>
                        <Card.Header as="h5">Login</Card.Header>
                        <Card.Body>
                            <Form>
                                <FloatingLabel controlId="user" label="Username" className="mb-3">
                                    <Form.Control type="text" placeholder={""} value={user} onChange={e => {setUser(e.target.value); e.preventDefault();}}/>
                                </FloatingLabel>
                                <FloatingLabel controlId="password" label="Passwort" className="mb-3">
                                    <Form.Control type="password" placeholder={""} value={password} onChange={e => {setPassword(e.target.value); e.preventDefault();}}/>
                                </FloatingLabel>
                                <Button onClick={event => {
                                    if(user === 'user' && password === 'user') {
                                        setUsername('User');
                                        navigate("/view/user/erfassen");
                                    } else if(user === 'admin' && password === 'admin') {
                                        setUsername('Admin');
                                        navigate("/view/uebersicht");
                                    }
                                }}>Login</Button>
                            </Form>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Row>
                                <Col>Admin:</Col>
                                <Col>admin</Col>
                                <Col>admin</Col>
                            </Row>
                            <Row>
                                <Col>User:</Col>
                                <Col>user</Col>
                                <Col>user</Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </div>
    );
}