import React from 'react';
import { Routes, Route } from "react-router-dom";
import Login from "./view/Login";
import Layout from "./view/Layout";
import Erfassen from "./view/user/Erfassen";
import Settings from "./view/user/Settings";
import Info from "./view/user/Info";
import Uebersicht from "./view/admin/Uebersicht";
import {useRecoilValue} from "recoil";
import {user} from "./atoms/user";

function App() {
    const username = useRecoilValue<string>(user)

  return (
      <Routes>
        <Route index element={<Login />} />
        <Route path="view" element={<Layout name={username}/>}>
          <Route path="user" element={<Info />} />
          <Route path="user/settings" element={<Settings />} />
          <Route path="user/erfassen" element={<Erfassen />} />
          <Route path="uebersicht" element={<Uebersicht />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
  );
}

export default App;
