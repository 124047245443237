import React, {useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";

export default function Erfassen() {
    const [start, setStart] = useState<string>('');
    const [end, setEnd] = useState<string>('');

    return (
        <Container>
            <Row>
                <Col sm={12}>Arbeitszeiterfassung</Col>
            </Row>
            <Form.Group as={Row} className="mb-3" controlId="start">
                <Form.Label column sm="1">
                    Start:
                </Form.Label>
                <Col sm="11">
                    <Form.Control type="datetime-local" value={start} onChange={e => {setStart(e.target.value); e.preventDefault();}}/>
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="end">
                <Form.Label column sm="1">
                    End:
                </Form.Label>
                <Col sm="11">
                    <Form.Control type="datetime-local" value={end} onChange={e => {setEnd(e.target.value); e.preventDefault();}} />
                </Col>
            </Form.Group>
            <Row>
                <Col sm="1">Zeit: </Col>
                <Col sm="11">{ (Date.parse(end) - Date.parse(start)) / 3600000 }</Col>
            </Row>
        </Container>
    );
}